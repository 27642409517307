// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   backend: 'https://pdl1-qa.ethosh.net/lms/api/', //dev
//   // backend: 'https://www.cdx-elearning.agilent.com/lms/api/',
//   // backend: 'https://www.stgcdx-elearning.agilent.com/lms/api/', 

//   // backend: 'http://localhost:9090/api/',
//   batchId: 3001,
//   batchName: 'Agilent batch',
//   okta_base:'https://dev-login.agilent.com/',
//   registerURL:'https://dev-register.agilent.com/',
//   oidc:{
//     clientId:'0oa22oox92oFWNfCN0h8',
//     issuer:'https://dev-login.agilent.com/oauth2/default',
//     redirecturi:'https://pdl1-qa.ethosh.net/user/login/callback',  //dev
//     scopes:['openid','profile','email'],
// },
//  ATGUrl:'https://stgwww1.agilent.com/common/atgPostLogin.jsp',
//   ATGLogoutUrl:'https://stgwww1.agilent.com/store/logoutUser.jsp',
//  ATGcallback:'https://pdl1-qa.ethosh.net/user/courses/',
//   ATGcallbackDashborad:'https://pdl1-qa.ethosh.net/user/lms-courses'
// };

export const environment = {
  production: false,

  // backend: 'https://www.cdx-elearning.agilent.com/lms/api/',
  backend: 'https://www.stgcdx-elearning.agilent.com/lms/api/', 

  // backend: 'http://localhost:9090/api/',
  batchId: 3001,
  batchName: 'Agilent batch',
  okta_base:'https://stg-login.agilent.com/',
  registerURL:'https://stgwww.agilent.com/',
  oidc:{
    clientId:'0oa1572fl393eFT2i358',
    issuer:'https://stg-login.agilent.com/oauth2/default',
    redirecturi:'https://www.stgcdx-elearning.agilent.com/user/login/callback',  
    scopes:['openid','profile','email']
},
 ATGUrl:'https://stgwww.agilent.com/common/atgPostLogin.jsp',
  ATGLogoutUrl:'https://stgwww.agilent.com/store/logoutUser.jsp',
  ATGcallback:'https://www.stgcdx-elearning.agilent.com/user/courses/',
 ATGcallbackDashborad:'https://www.stgcdx-elearning.agilent.com/user/lms-courses'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
